<template>
  <form action class="form" @submit.prevent="submit" ref="form">
    <div class="row">
      <div class="title col-sm-12 text-center">
        <h4>Ingresa tus datos</h4>
      </div>
    </div>
    <div class="form-group mt-3" :class="{ 'has-danger': $v.nombre.$error }">
      <input
        type="text"
        class="form-control"
        id="nombre"
        v-model.trim="$v.nombre.$model"
        aria-describedby="Nombre"
        placeholder="Nombre(s)"
      />
      <div class="error" v-if="hasErrors">
        <small v-if="!$v.nombre.required">El campo nombre es obligatorio.</small>
      </div>
    </div>
    <div class="form-group mt-3" :class="{ 'has-danger': $v.apellido_paterno.$error }">
      <input
        type="text"
        class="form-control"
        id="apellido_paterno"
        v-model.trim="$v.apellido_paterno.$model"
        aria-describedby="Apellido paterno"
        placeholder="Apellido paterno"
      />
      <div class="error" v-if="hasErrors">
        <small v-if="!$v.apellido_paterno.required">El campo apellido paterno es obligatorio.</small>
      </div>
    </div>
    <div class="form-group mt-3" :class="{ 'has-danger': $v.apellido_materno.$error }">
      <input
        type="text"
        class="form-control"
        id="apellido_materno"
        v-model.trim="$v.apellido_materno.$model"
        aria-describedby="Apellido materno"
        placeholder="Apellido materno"
      />
      <div class="error" v-if="hasErrors">
        <small v-if="!$v.apellido_materno.required">El campo apellido materno es obligatorio.</small>
      </div>
    </div>
    <div class="form-group mt-3" :class="{ 'has-danger': $v.curp.$error }">
      <input
        type="text"
        class="form-control"
        id="curp"
        maxlength="18"
        v-model.trim="$v.curp.$model"
        aria-describedby="curp"
        placeholder="CURP"
      />
      <div class="error" v-if="hasErrors">
        <small v-if="!$v.curp.required">El campo CURP es obligatorio.</small>
        <small v-if="!$v.curp.minLength || !$v.curp.maxLength">El CURP solo debe tener 18 digitos.</small>
      </div>
    </div>
    <div class="form-group mt-3" :class="{ 'has-danger': $v.email.$error }">
      <input
        type="email"
        class="form-control form-control-alternative"
        id="email"
        v-model.trim="$v.email.$model"
        aria-describedby="correo electronico"
        placeholder="Correo electrónico"
      />
      <div class="error" v-if="hasErrors">
        <div class="is-invalid" v-if="!$v.email.email">
          <small>El correo electrónico debe ser valido.</small>
        </div>
        <div class="is-invalid" v-if="!$v.email.required">
          <small>El correo electrónico es requerido.</small>
        </div>
      </div>
    </div>
    <div class="form-group mt-3" :class="{ 'has-danger': $v.telefono.$error }">
      <input
        type="number"
        class="form-control form-control-alternative"
        id="telefono"
        maxlength="10"
        v-model.trim="$v.telefono.$model"
        aria-describedby="teléfono"
        placeholder="Teléfono"
      />
      <div class="error" v-if="hasErrors">
        <div class="is-invalid" v-if="!$v.telefono.minLength">
          <small>El teléfono debe tener 10 digitos.</small>
        </div>
        <div class="is-invalid" v-if="!$v.telefono.maxLength">
          <small>El teléfono solo debe tener 10 digitos.</small>
        </div>
        <div class="is-invalid" v-if="!$v.telefono.required">
          <small>El teléfono es requerido.</small>
        </div>
      </div>
    </div>
    <div class="form-check mt-3" :class="{ 'has-danger': $v.terms.$error }">
      <input type="checkbox" class="form-check-input" id="terms" v-model="terms" />
      <label class="form-check-label" for="terms"
        >Acepto las <a data-bs-toggle="modal" data-bs-target="#privacyNoticeModal"><ins>políticas de privacidad</ins></a></label
      >
      <div class="error" v-if="hasErrors">
        <small v-if="!$v.terms.sameAs">Debes aceptar la política de privacidad</small>
      </div>
    </div>
    <div class="row mt-4">
			<div class="col-sm-12 text-center">
				<button id="obtenerCredito" type="submit" class="">Quiero Remodelar</button>
			</div>
		</div>
    <p class="text-center mt-4">¡Un socio promotor te contactará!</p>

    <PrivacyNoticeModal />
  </form>
</template>

<script>
import { required, email, minLength, maxLength, sameAs, alphaNum } from "vuelidate/lib/validators";
import PrivacyNoticeModal from "./PrivacyNoticeModal.vue";
import OrdenesAPI from '@/api/ordenes.api'

export default {
  data: () => ({
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    email: "",
    telefono: "",
    estado: "",
    curp: "",
    terms: false,
    hasErrors: false
  }),
  validations: {
    nombre: {
      required
    },
    apellido_paterno: {
      required
    },
    apellido_materno: {
      required
    },
    curp: {
      required,
      minLength: minLength(18),
      maxLength: maxLength(18)
    },
    email: {
      required,
      email
    },
    telefono: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10)
    },
    terms: {
      sameAs: sameAs(() => true),
    }
  },
  components: {
    PrivacyNoticeModal
  },
  methods: {
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.hasErrors = true;
        return;
      }

      const loading = this.$loading.show({ container: this.$refs.form })
			try {
				const response = await OrdenesAPI.create({
          nombre: this.nombre,
          apellido_paterno: this.apellido_paterno,
          apellido_materno: this.apellido_materno,
          correo_electronico: this.email,
          telefono_celular: this.telefono,
          curp: this.curp,
        })

				this.clear()
				this.$swal({
					type: 'success',
					title: 'Éxito',
					position: 'center',
					text: '¡Un socio promotor te contactará!',
					confirmButtonColor: '#24b361',
				})
			} finally {
				loading.hide()
			}
    },
    clear() {
			this.nombre = null
      this.apellido_paterno = null
      this.apellido_materno = null
      this.email = null
      this.telefono = null
      this.curp = null
      this.terms = false
      this.$v.$reset()
		}
  }
};
</script>
