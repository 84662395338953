import Vue from 'vue'
import App from './App.vue'

import './plugins';


Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.config.silent = process.env.NODE_ENV === 'production';
Vue.config.productionTip = process.env.NODE_ENV !== 'production';

new Vue({
  render: h => h(App),
  mounted() {
    if (process.env.VUE_APP_REDIRECT_ONLOAD) {
      window.location.href = process.env.VUE_APP_REDIRECT_ONLOAD
    }
  }
}).$mount('#app')
